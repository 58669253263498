.MuiSnackbarContent-root {
  &.snackbar-warning {
    background-color: var(--accent-warning);
  }
  &.snackbar-info {
    background-color: var(--accent-info);
  }
  &.snackbar-success {
    background-color: var(--accent-success);
  }
  &.snackbar-error {
    background-color: var(--accent-danger);
  }
}

.MuiSnackbarContent-message {
  padding: 10px 0 !important;
  #client-snackbar {
    display: flex;
  }
  .MuiIcon-root {
    margin-right: 16px;
    font-size: 18px;
    line-height: 24px;
  }
}
