@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap');
@import url('https://use.typekit.net/kax8yxy.css');

:root {
  --base-font-size: 16px;
  --primary-main: #06083c;
  --primary-light: #b4b5c5;
  --primary-dark: rgb(0, 0, 25);
  --primary-contrastText: #ffffff;
  --secondary-main: #b50d4d;
  --secondary-light: #c43d71;
  --secondary-dark: #7f0936;
  --selection-bg: #f0cfdb;
  --secondary-contrastText: #ffffff;
  --main-bg-color: #ffffff;
  --text-main: #131313;
  --text-light: #999999;
  --accent-warning: #eeb254;
  --accent-success: #5cb279;
  --accent-danger: #da3349;
  --accent-info: #336bda;
  --body-font: 'Lato', sans-serif;

  // Amplify
  --amplify-primary-color: #8700d7;
  --amplify-primary-tint: #8700d7;
  --amplify-primary-shade: #8700d7;
}

@at-root {
  @viewport {
    width: device-width;
  }
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  cursor: initial;
}

abbr[title],
dfn[title] {
  cursor: help;
}

button,
input[type='button'],
input[type='checkbox'],
input[type='radio'],
input[type='reset'],
input[type='submit'],
label[for],
select {
  cursor: pointer;
}

code {
  cursor: text;
}

button[disabled],
input[disabled],
select[disabled] {
  cursor: default;
}

html {
  color: var(--text-main);
  font-family: var(--body-font);
  line-height: 1.575;
}

::selection {
  background: var(--selection-bg);
}

// Workarounds to override user agent autofill styles
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
select:-webkit-autofill:focus {
  font-size: var(--base-font-size) !important; // Can't change font size, Chrome bug
  -webkit-text-fill-color: var(--text-main);
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s, font-size 5000s ease-in-out 0s;
}
